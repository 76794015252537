<template>
    <div ref="loadingFrame"
        class="fixed top-0 left-0 w-screen h-dvh flex justify-center items-center overflow-hidden bg-[#141414] transition-all duration-700 z-50">
        <video ref="backgroundVideo" muted playsinline class="w-full h-auto object-cover"
            poster="../assets/video/defin-loading-poster.png">
            <source src="../assets/video/defin-loading.mp4" type="video/mp4">
        </video>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useState } from '../controller/state';

export default {
    name: 'LoadingDefin',

    setup() {
        const state = useState();
        const loadingFrame = ref(null);
        const backgroundVideo = ref(null);

        function toggleScrollControls(enable) {
            if (enable) {
                document.removeEventListener('wheel', preventScroll, { passive: false });
                document.removeEventListener('keydown', preventKeyScroll);
                document.removeEventListener('touchmove', preventScroll, { passive: false });
            } else {
                document.addEventListener('wheel', preventScroll, { passive: false });
                document.addEventListener('keydown', preventKeyScroll);
                document.addEventListener('touchmove', preventScroll, { passive: false });
            }
        }

        function preventScroll(e) {
            e.preventDefault();
        }

        function preventKeyScroll(e) {
            if (e.code === 'Space' || ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.code)) {
                e.preventDefault();
            }
        }

        function disableRightClickAndF12() {
            document.addEventListener('contextmenu', function (e) {
                e.preventDefault();
            });

            // document.addEventListener('keydown', function (e) {
            //     if (e.code === 'F12') {
            //         e.preventDefault();
            //     }
            // });
        }

        function hideLoading() {
            loadingFrame.value.classList.add('opacity-0');
            setTimeout(() => {
                loadingFrame.value.classList.add('hidden');
            }, 700);
        }

        function loadingProcess() {
            toggleScrollControls(false);
            disableRightClickAndF12();
            window.addEventListener('load', function () {
                backgroundVideo.value.play();
                backgroundVideo.value.addEventListener('ended', function () {
                    hideLoading();
                    toggleScrollControls(true);
                    state.setLoadingState(true);
                }, { once: true });
            }, { once: true });
        }

        onMounted(() => {
            loadingProcess();
        });

        return {
            loadingFrame,
            backgroundVideo
        }
    }
}
</script>

<style scoped></style>