import { defineStore } from 'pinia'

export const useState = defineStore('state', {
    state: () => ({
        loadingComplete: false,
    }),
    actions: {
        setLoadingState(state) {
            this.loadingComplete = state;
        },
        getLoadingState() {
            return this.loadingComplete;
        }
    },
})