<template>
  <div ref="homeFrame" class="relative w-full h-auto bg-[#0C0C0C]">
    <!-- nav -->
    <div v-if="!isMobile" class="fixed top-[1vw] left-0 w-full h-[3.5vw] flex justify-center z-40">
      <div
        class="relative w-[95%] h-full bg-[#18181833] backdrop-blur-[15px] border border-[#BDFF00] rounded-[22px] box-shadow flex justify-center items-center">
        <img src="../assets/logo.webp" class="h-[95%] -translate-x-[0.5vw]">
        <h1 class="font-azonix text-[#fff] text-[2.5vw] leading-none pt-[0.3%] font-bold">DEFIN</h1>
        <div class="absolute top-0 left-0 h-full w-full flex">
          <div class="flex-1 h-full flex justify-end">
            <div @click="scrollToElement(about)" class="h-full w-fit flex justify-center items-center">
              <div ref="aboutNavBtn" class="relative h-auto w-fit mr-[7vw] nav-btn-hover">
                <h1 class="font-azonix text-[#fff] text-[1.5vw] leading-none pt-[2%] font-normal">ABOUT US</h1>
                <div
                  class="absolute top-[100%] left-0 h-[10%] w-full bg-gradient-to-r from-transparent via-[#bbff00] to-transparent rounded-[25%] nav-btn transition-all opacity-0">
                </div>
              </div>
            </div>
            <div @click="scrollToElement(data2Earn)" class="h-full w-fit flex justify-center items-center">
              <div ref="earnNavBtn" class="relative h-auto w-fit mr-[13vw] nav-btn-hover">
                <h1 class="font-azonix text-[#fff] text-[1.5vw] leading-none pt-[2%] font-normal">EARN $<span
                    class="text-[1vw] font-black">ve</span>DEF</h1>
                <div
                  class="absolute top-[100%] left-0 h-[10%] w-full bg-gradient-to-r from-transparent via-[#bbff00] to-transparent rounded-[25%] nav-btn transition-all opacity-0">
                </div>
              </div>
            </div>
          </div>
          <div class="flex-1 h-full flex justify-start">
            <div @click="scrollToElement(verifierNode)" class="h-full w-fit flex justify-center items-center">
              <div ref="nodeSaleNavBtn" class="relative h-auto w-fit ml-[13vw] nav-btn-hover">
                <h1 class="font-azonix text-[#fff] text-[1.5vw] leading-none pt-[2%] font-normal">NODE SALES</h1>
                <div
                  class="absolute top-[100%] left-0 h-[10%] w-full bg-gradient-to-r from-transparent via-[#bbff00] to-transparent rounded-[25%] nav-btn transition-all opacity-0">
                </div>
              </div>
            </div>
            <div class="h-full w-fit flex justify-center items-center">
              <div class="relative h-auto w-fit ml-[7vw] nav-btn-hover">
                <h1 class="font-azonix text-[#fff] text-[1.5vw] leading-none pt-[2%] font-normal">WHITE PAPER</h1>
                <div
                  class="absolute top-[100%] left-0 h-[10%] w-full bg-gradient-to-r from-transparent via-[#bbff00] to-transparent rounded-[25%] nav-btn transition-all opacity-0">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!isMobile" class="w-full h-auto" data-scroll-container>
      <video data-scroll-section id="banner" muted playsinline autoplay loop class="w-full h-auto">
        <source src="../assets/video/banner-video.webm" type="video/webm">
      </video>
      <div data-scroll-section id="about" ref="about" class="relative w-full h-auto z-30">
        <video muted playsinline autoplay loop class="w-full h-auto">
          <source src="../assets/video/about-video.webm" type="video/webm">
        </video>
        <div class="absolute top-0 left-0 w-full h-[10%] bg-gradient-to-b from-[#0C0C0C] to-transparent"></div>
        <img src="../assets/img/line-connect.png" class="absolute top-0 left-[57vw] w-[20vw] h-auto">
        <img @click="openWebLink('https://t.me/xdefin_bot')" src="../assets/img/lauchapp-btn.png"
          class="absolute top-[27.5vw] left-[71vw] w-[18vw] h-auto hover:scale-125 transition-all duration-300 origin-left cursor-pointer active:scale-90">
        <img src="../assets/img/cube-black-rotate.webp" class="absolute bottom-0 left-0 w-[13vw] h-auto cube-animation">
      </div>
      <div data-scroll-section id="earn" ref="earn" class="relative w-full h-auto z-10">
        <img ref="backgroundEarn" src="../assets/img/background-earn.webp"
          class="w-full h-auto transition-all duration-700">
        <div class="absolute top-0 left-0 w-full h-[5%] bg-gradient-to-b from-[#0C0C0C] to-transparent"></div>
        <div class="absolute bottom-0 left-0 w-full h-[5%] bg-gradient-to-t from-[#0C0C0C] to-transparent"></div>
        <img ref="starsEarn" src="../assets/img/stars-background-earn.webp"
          class="absolute top-0 right-0 w-full h-full transition-all duration-1000">
        <div class="absolute top-0 left-0 w-full h-[50vw] flex justify-center items-center">
          <img src="../assets/img/gradient-background-earn.webp" class="h-[90%] mix-blend-plus-lighter opacity-50">
        </div>
        <img src="../assets/img/shape-background-earn.webp"
          class="absolute top-0 right-0 w-[10vw] h-auto -translate-y-[5vw] z-40">
        <video ref="privateEarn" muted playsinline autoplay loop
          class="absolute top-[7vw] left-[10vw] w-[21vw] h-auto transition-all duration-300 origin-bottom-left">
          <source src="../assets/video/mobile/card.mov" type="video/quicktime">
          <source src="../assets/video/card-earn.webm" type="video/webm">
        </video>
        <img ref="privateConnect" src="../assets/img/line-earn.webp"
          class="absolute top-[27.5vw] left-[17vw] w-[25vw] h-auto z-10 transition-all duration-300 origin-top-leftleft">
        <div class="absolute top-[3vw] left-0 w-full h-[35vw] flex justify-center items-center">
          <img ref="aiModel" src="../assets/img/earn-ai-model.webp"
            class="h-full transition-all duration-300 origin-bottom">
        </div>
        <div ref="titleFrameBottom"
          class="absolute top-[37vw] left-0 w-full h-auto flex justify-center transition-all duration-300">
          <img src="../assets/img/earn-web3.webp" class="w-[60%] h-auto">
          <div @click="earnMoreDetailBottomBtnClick"
            class="absolute bottom-[15%] left-[43.7%] w-[9.5%] h-[11%] border border-[#ffffff80] rounded-[10px] flex overflow-hidden btn-detail cursor-pointer">
            <div
              class="h-full w-4/5 bg-gray-600 boxshadow-btn-detail flex justify-center items-center btn-bg-left transition-all duration-300">
              <h1
                class="font-azonix text-[#fff] text-[0.8vw] leading-none pt-[2%] font-normal btn-text-left transition-all duration-300">
                MORE DETAIL
              </h1>
            </div>
            <div
              class="h-full w-1/5 bg-[#c1ff18] flex justify-center items-center btn-bg-right transition-all duration-300">
              <h1
                class="font-azonix text-[#fff] text-[1vw] leading-none pt-[2%] font-normal btn-text-right transition-all duration-300">
                &rarr;</h1>
            </div>
          </div>
        </div>
        <div ref="detailFrameBottom"
          class="absolute top-[37vw] left-0 w-full h-auto flex justify-center transition-all duration-300 opacity-0">
          <img src="../assets/img/detail-web3-earn.webp" class="w-[50%] h-auto">
          <div class="absolute top-[110%] left-0 w-full h-[20%] flex justify-center items-center">
            <div @click="closeMoreDetailBottomBtnClick"
              class="h-full w-[5.5%] border border-[#ffffff80] rounded-[10px] flex overflow-hidden btn-back-detail cursor-pointer">
              <div
                class="h-full w-1/3 flex justify-center items-center bg-[#c1ff18] bg-back-detail-left transition-all duration-300">
                <h1
                  class="font-azonix text-[#fff] text-[1vw] leading-none pt-[2%] font-normal transition-all duration-300 -rotate-45 btn-text-back-detail-left">
                  &larr;</h1>
              </div>
              <div
                class="h-full w-2/3 flex justify-center items-center bg-gray-600 bg-back-detail-right boxshadow-btn-back-detail transition-all duration-300">
                <h1
                  class="font-azonix text-[#fff] text-[0.8vw] leading-none pt-[3%] font-normal transition-all duration-300 btn-text-back-detail-right">
                  BACK
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div ref="titleEarn" class="absolute top-[10.3vw] left-[59.5vw] w-[37vw] h-auto transition-all duration-300">
          <img src="../assets/img/earn-title.webp" class="w-full h-auto">
          <div ref="detailBtn" @click="earnMoreDetailRightBtnClick"
            class="absolute bottom-[10%] left-[8%] w-[25%] h-[12%] border border-[#ffffff80] rounded-[10px] flex overflow-hidden btn-detail cursor-pointer">
            <div
              class="h-full w-4/5 bg-gray-600 boxshadow-btn-detail flex justify-center items-center btn-bg-left transition-all duration-300">
              <h1
                class="font-azonix text-[#fff] text-[0.8vw] leading-none pt-[2%] font-normal btn-text-left transition-all duration-300">
                MORE DETAIL
              </h1>
            </div>
            <div
              class="h-full w-1/5 bg-[#c1ff18] flex justify-center items-center btn-bg-right transition-all duration-300">
              <h1
                class="font-azonix text-[#fff] text-[1vw] leading-none pt-[2%] font-normal btn-text-right transition-all duration-300">
                &rarr;</h1>
            </div>
          </div>
          <div ref="detailBackBtn" @click="closeMoreDetailRightBtnClick"
            class="absolute bottom-[10%] left-[8%] w-[15%] h-[12%] border border-[#ffffff80] rounded-[10px] flex overflow-hidden btn-back-detail hidden cursor-pointer">
            <div
              class="h-full w-1/3 flex justify-center items-center bg-[#c1ff18] bg-back-detail-left transition-all duration-300">
              <h1
                class="font-azonix text-[#fff] text-[1vw] leading-none pt-[2%] font-normal transition-all duration-300 -rotate-45 btn-text-back-detail-left">
                &larr;</h1>
            </div>
            <div
              class="h-full w-2/3 flex justify-center items-center bg-gray-600 bg-back-detail-right boxshadow-btn-back-detail transition-all duration-300">
              <h1
                class="font-azonix text-[#fff] text-[0.8vw] leading-none pt-[3%] font-normal transition-all duration-300 btn-text-back-detail-right">
                BACK
              </h1>
            </div>
          </div>
        </div>
        <div ref="detailEarn"
          class="absolute top-[24.3vw] left-[56.6vw] w-[33vw] h-auto transition-all duration-300 opacity-0">
          <img src="../assets/img/detail-earn.webp" class="w-full h-auto">
        </div>
        <img src="../assets/img/cube-black.webp" class="absolute bottom-0 right-0 w-[13vw] h-auto cube-animation">
      </div>
      <video data-scroll-section id="data2Earn" ref="data2Earn" muted playsinline autoplay loop class="w-full h-auto">
        <source src="../assets/video/data-2-earn.webm" type="video/webm">
      </video>
      <img data-scroll-section id="advisor" src="../assets/img/advisor.webp" class="w-full h-auto">
      <video data-scroll-section id="verifierNode" ref="verifierNode" muted playsinline autoplay loop
        class="w-full h-auto">
        <source src="../assets/video/verifier-node.webm" type="video/webm">
      </video>
      <div data-scroll-section id="partner" class="relative w-full h-auto flex items-start">
        <img src="../assets/img/background-partner.webp" class="h-full">
        <img src="../assets/img/ellipse-left-partner.webp"
          class="absolute top-[-10vw] left-0 w-[25vw] h-auto mix-blend-plus-lighter">
        <img src="../assets/img/ellipse-right-partner.webp"
          class="absolute top-[-10vw] right-0 w-[25vw] h-auto mix-blend-plus-lighter">
        <div class="absolute bottom-0 left-0 w-full h-[3vw] bg-gradient-to-t from-[#0C0C0C] to-transparent"></div>
        <div class="absolute top-0 left-0 w-full h-auto flex flex-col justify-start items-center gap-[5vw]">
          <img src="../assets/img/title-partner.webp" class="w-[80%] h-auto mt-[5vw]">
          <div class="relative w-[85%] h-auto">
            <img src="../assets/img/layout-partner.webp" class="w-full h-auto">
            <div class="absolute top-0 left-0 h-full w-full flex justify-center items-center">
              <div class="w-[80%] h-[65%] grid grid-cols-3 grid-rows-2 gap-[4vw]">
                <div v-for="partner in partnerList" :key="partner" @click="openWebLink(partner.link)"
                  class="relative transition-all duration-300 hover:scale-110 partner-hover-custom origin-top-left cursor-pointer">
                  <img src="../assets/img/tag-partner.webp" class="w-full h-auto">
                  <div class="absolute top-0 left-0 w-full h-full flex justify-start items-center">
                    <img :src="partner.logoUrl" class="h-1/2 w-auto ml-[2vw] mr-[1vw]">
                    <h1 class="font-azonix text-[#fff] text-[1.7vw] leading-none pt-[2%] font-normal">{{ partner.name }}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- footer -->
      <div data-scroll-section class="relative w-full h-auto">
        <img src="../assets/img/backfooter.webp" class="w-full h-auto">
        <div class="absolute top-0 left-0 w-full h-full flex flex-col justify-end items-center">
          <div class="w-full h-[40%] flex justify-center">
            <div class="w-[90%] h-full flex justify-between items-center">
              <div class="h-full w-1/2 flex flex-col gap-[10%]">
                <div class="w-full h-[30%] flex gap-[2%] items-center">
                  <img src="../assets/img/logo.webp" class="h-full">
                  <h1 class="font-azonix text-[#fff] text-[3.5vw] leading-none font-bold pt-[1%]">
                    DEFIN</h1>
                </div>
                <div class="relative h-auto w-fit">
                  <h1 class="font-azonix text-[#fff] text-[1.5vw] leading-none font-normal pt-[1%]">
                    Contribute to Earn now</h1>
                  <div
                    class="absolute top-0 left-0 w-full h-[10%] bg-gradient-to-r from-[#c1ff18] to-transparent -translate-y-[130%]">
                  </div>
                </div>
              </div>
              <div class="h-full w-1/2 flex flex-col gap-[10%]">
                <div class="w-full h-auto flex justify-end mt-[3%]">
                  <img @click="openWebLink('https://t.me/xdefin_bot')" src="../assets/img/lauchapp-btn.png"
                    class="w-[41%] h-auto hover:scale-110 active:scale-95 transition-all duration-300 cursor-pointer">
                </div>
                <div class="w-full h-auto flex justify-end gap-[3%]">
                  <img @click="openWebLink('https://t.me/defin_ann')" src="../assets/img/social/tele.webp"
                    class="w-[8%] h-auto hover:scale-110 active:scale-95 transition-all duration-300 cursor-pointer">
                  <img @click="openWebLink('https://t.me/defin_official')" src="../assets/img/social/tele.webp"
                    class="w-[8%] h-auto hover:scale-110 active:scale-95 transition-all duration-300 cursor-pointer">
                  <img @click="openWebLink('https://x.com/DeFIN_app')" src="../assets/img/social/x.webp"
                    class="w-[8%] h-auto hover:scale-110 active:scale-95 transition-all duration-300 cursor-pointer">
                  <img @click="openWebLink('#')" src="../assets/img/social/doc.webp"
                    class="w-[8%] h-auto hover:scale-110 active:scale-95 transition-all duration-300 cursor-pointer">
                </div>
              </div>
            </div>
          </div>
          <div class="w-full h-[17.5%] flex justify-center">
            <div class="w-[90%] h-full flex justify-between items-center">
              <div class="h-[35%] w-[50%] flex justify-start items-center gap-[2%]">
                <img src="../assets/img/copyright.webp" class="h-full">
                <h1 class="font-azonix text-[#fff] text-[1.3vw] leading-none font-normal pt-[1%]">2024 DEFIN. All rights
                  reserved</h1>
              </div>
              <div class="h-[35%] w-[50%] flex justify-end items-center gap-[5%]">
                <div class="nav-btn-hover">
                  <h1 class="font-azonix text-[#fff] text-[1.3vw] leading-none font-normal pt-[1%] cursor-pointer">Terms
                    of Service</h1>
                </div>
                <div class="nav-btn-hover">
                  <h1 class="font-azonix text-[#fff] text-[1.3vw] leading-none font-normal pt-[1%] cursor-pointer">
                    Privacy Policy</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- nav-mobile -->
    <div v-if="isMobile" class="fixed top-[6vw] left-0 w-full h-[10vw] flex justify-center z-40">
      <div
        class="relative w-[95%] h-full bg-gradient-to-r from-transparent to-[#c1ff1857] rounded-full backdrop-blur-sm flex">
        <div class="h-full w-1/2 flex justify-start items-center">
          <img src="../assets/img/logo.webp" class=" h-full origin-left scale-150">
          <h1 class="font-azonix text-[#fff] text-[6vw] leading-none font-normal pt-[2%] pl-[10%]">DEFIN</h1>
        </div>
        <div class="h-full w-1/2 flex justify-end items-center">
          <img @click="openWebLink('https://t.me/xdefin_bot')" btn src="../assets/img/mobile/menu-btn.webp"
            class="h-[61%] transition-all pr-[10%]">
        </div>
      </div>
    </div>

    <div v-if="isMobile" class="w-full h-auto">
      <video muted playsinline autoplay loop class="w-full h-auto">
        <source src="../assets/video/mobile/banner.webm" type="video/webm">
      </video>
      <div class="relative w-full h-auto flex justify-center items-center">
        <img src="../assets/img/mobile/defin-text-gadient.webp" class="w-[95%] h-auto translate-y-[20%]">
        <div class="absolute top-[100%] left-0 w-full h-full flex justify-center items-start">
          <img @click="openWebLink('https://t.me/xdefin_bot')" btn src="../assets/img/mobile/lauchapp-btn.png"
            class="w-[50%] h-auto transition-all z-20">
        </div>
      </div>
      <div class="relative w-full h-auto flex justify-center items-center">
        <img src="../assets/img/mobile/about-cpu.webp" class="w-full h-auto">
        <div class="absolute bottom-[5%] left-0 w-full h-auto flex justify-center items-center">
          <img src="../assets/img/mobile/about-title.webp" class="w-[95%] h-auto">
        </div>
      </div>
      <div
        class="relative w-full h-[80vw] flex justify-center items-center bg-gradient-to-t from-[#c1ff1825] to-transparent">
        <img ref="aboutDetail1" src="../assets/img/mobile/about-ai.webp"
          class="w-[80%] transition-all duration-300 -translate-y-[10%] translate-x-[3.5%]">
        <img ref="aboutDetail2" src="../assets/img/mobile/about-detail.webp"
          class="w-[80%] transition-all duration-300 opacity-0 hidden">
        <img src="../assets/img/mobile/about-line-left.webp" class="absolute top-[7%] left-[2.5%] h-[70%] w-auto">
        <img src="../assets/img/mobile/about-line-right.webp" class="absolute top-[20%] right-[1.5%] h-[70%] w-auto">
        <div class="absolute bottom-0 left-0 w-full h-[25%] flex justify-center items-center gap-[10%]">
          <div @click="detailAboutToggle(1)" ref="aboutDetailBtnLeft" btn
            class="h-1/2 aspect-square border border-[#c1ff1875] rounded-lg flex justify-center items-center transition-all about-box-shadow-btn">
            <img src="../assets/img/mobile/about-arrow-left-line.png" class="w-[60%]">
          </div>
          <div @click="detailAboutToggle(2)" ref="aboutDetailBtnRight" btn
            class="h-1/2 aspect-square border border-[#c1ff1875] rounded-lg flex justify-center items-center transition-all">
            <img src="../assets/img/mobile/about-arrow-right-line.png" class="w-[60%]">
          </div>
        </div>
      </div>
      <div class="relative w-full h-auto overflow-hidden">
        <img src="../assets/img/mobile/background-about2.webp" class="w-full h-auto">
        <div class="absolute bottom-0 left-0 w-full h-[3%] bg-gradient-to-t from-[#0C0C0C] to-transparent"></div>
        <div
          class="absolute top-0 left-0 h-1/2 w-full bg-gradient-to-b from-[#c1ff1825] to-transparent flex justify-center items-start">
          <img src="../assets/img/mobile/defin-text-gadient.webp" class="w-[95%] h-auto">
        </div>
        <div ref="about2Chip" class="absolute top-[12%] left-0 w-3/5 h-auto transition-all duration-700">
          <img src="../assets/img/mobile/about2-chip.webp" class="w-full">
        </div>
        <div ref="about2AIModel" class="absolute top-[2%] left-[30%] w-3/5 h-auto transition-all duration-700">
          <img src="../assets/img/mobile/about2-ai-model.webp" class="w-full">
        </div>
        <div class="absolute top-[25%] w-full h-auto flex justify-center">
          <div class="relative w-[95%] h-auto">
            <img src="../assets/img/mobile/about2-web3-background.webp" class="w-full">
            <div ref="about2Line"
              class="absolute top-0 left-0 w-full h-full flex justify-center items-center transition-all duration-500">
              <div class="w-full h-[1%] flex justify-center items-center">
                <div
                  class="h-full w-[70%] rounded-[50%] bg-gradient-to-r from-transparent via-[#c1ff18] to-transparent">
                </div>
              </div>
            </div>
            <div ref="about2Title"
              class="absolute top-0 left-0 w-full h-full flex flex-col justify-between items-center transition-all duration-300">
              <div class="w-full h-1/2 flex items-center justify-center">
                <img src="../assets/img/mobile/about2-web3-title.webp" class="w-4/5 mt-[5%]">
              </div>
              <div class="w-full h-1/2 flex flex-col items-center justify-center gap-[10%]">
                <h1 class="font-azonix text-[#fff] text-[4vw] leading-none font-normal text-center">EMPOWER THE NEXT
                  MILLION <br> USERS TO JOIN WEB3</h1>
                <div @click="detailAbout2Toggle(1)" btn
                  class="h-[20%] w-1/2 border border-gray-400 rounded-lg flex overflow-hidden transition-all">
                  <div
                    class="h-full w-4/5 bg-gray-600 boxshadow-btn-detail nav-active flex justify-center items-center">
                    <h1 class="font-azonix text-[#fff] text-[4vw] leading-none font-normal text-center pt-[2%]">MORE
                      DETAIL</h1>
                  </div>
                  <div class="h-full w-1/5 bg-[#c1ff18c7] flex justify-center items-center">
                    <img src="../assets/img/mobile/about-arrow-right-line.png" class="w-3/4 h-auto">
                  </div>
                </div>
              </div>
            </div>
            <div ref="about2Detail"
              class="absolute top-0 left-0 w-full h-full flex flex-col justify-start items-center transition-all duration-300 opacity-0 hidden">
              <div class="w-full h-[15%] mt-[8%] mb-[3%]">
                <img @click="detailAbout2Toggle(2)" btn src="../assets/img/mobile/about2-arrow-green-left.png"
                  class="h-full w-auto pl-[8%]">
              </div>
              <img src="../assets/img/mobile/about2-detail.webp" class="w-4/5">
            </div>
          </div>
        </div>
        <div class="absolute top-[58%] w-full h-auto flex justify-center">
          <img src="../assets/img/mobile/about2-title2.webp" class="w-[95%] -translate-x-[2%]">
        </div>
        <div class="absolute top-[70%] w-full h-[80vw] flex flex-col items-center gap-[5%]">
          <video ref="about2CardTitle" muted playsinline autoplay loop
            class="w-auto h-[80%] transition-all duration-300">
            <source src="../assets/video/mobile/card.mov" type="video/quicktime">
            <source src="../assets/video/card-earn.webm" type="video/webm">
          </video>
          <img ref="about2CardContent" src="../assets/img/mobile/about2-detail2.webp"
            class="h-[80%] w-auto translate-y-[5%] transition-all duration-300 opacity-0 hidden">
          <div @click="detail2About2Toggle(1)" ref="about2MoreDetail2Btn" btn
            class="h-[9.5%] w-1/2 border border-gray-400 rounded-lg flex overflow-hidden transition-all">
            <div class="h-full w-4/5 bg-gray-600 boxshadow-btn-detail nav-active flex justify-center items-center">
              <h1 class="font-azonix text-[#fff] text-[4vw] leading-none font-normal text-center pt-[2%]">MORE
                DETAIL</h1>
            </div>
            <div class="h-full w-1/5 bg-[#c1ff18c7] flex justify-center items-center">
              <img src="../assets/img/mobile/about-arrow-right-line.png" class="w-3/4 h-auto">
            </div>
          </div>
          <div @click="detail2About2Toggle(2)" ref="about2BackBtn" btn
            class="h-[9.5%] w-1/3 border border-gray-400 rounded-lg flex overflow-hidden transition-all opacity-0 hidden">
            <div class="h-full w-[28%] bg-[#c1ff18c7] flex justify-center items-center">
              <img src="../assets/img/mobile/about-arrow-right-line.png" class="w-3/4 h-auto rotate-180">
            </div>
            <div class="h-full w-[72%] bg-gray-600 boxshadow-btn-detail nav-active flex justify-center items-center">
              <h1 class="font-azonix text-[#fff] text-[4vw] leading-none font-normal text-center pt-[2%]">
                BACK</h1>
            </div>
          </div>
          <div class="absolute bottom-[3%] left-0 w-auto h-[65%]">
            <img src="../assets/img/mobile/about2-line2.png" class="h-full w-auto">
          </div>
          <div class="absolute top-0-[3%] right-0 w-auto h-[65%]">
            <img src="../assets/img/mobile/about2-line2.png" class="h-full w-auto rotate-180">
          </div>
        </div>
      </div>
      <div class="relative w-full h-auto flex flex-col overflow-hidden">
        <div class="relative w-full h-auto z-10">
          <img src="../assets/img/mobile/d2e-background.webp" class="w-full h-auto">
          <div class="absolute top-0 left-0 w-full h-[10%] bg-gradient-to-b from-[#0C0C0C] to-transparent"></div>
          <div class="absolute bottom-0 left-0 w-full h-[10%] bg-gradient-to-t from-[#0C0C0C] to-transparent"></div>
        </div>
        <div class="w-full h-[80vw] flex justify-start items-end z-0">
          <img src="../assets/img/cube-black-rotate.webp" class="w-1/2 h-auto cube-animation">
        </div>
        <div class="w-full h-auto flex justify-center z-10">
          <img src="../assets/img/mobile/d2e-detail.webp" class="w-full h-auto">
        </div>
        <div class="absolute top-[3%] left-0 w-full h-auto flex justify-center z-20">
          <img src="../assets/img/mobile/d2e-title.webp" class="w-[70%] h-auto">
          <div class="absolute top-[200%] left-0 h-[10%] w-full flex justify-center">
            <div class="h-full w-[70%] bg-gradient-to-r from-transparent via-[#c1ff18c7] to-transparent rounded-[50%]">
            </div>
          </div>
        </div>
        <div class="absolute top-[4%] left-0 w-[25%] h-auto">
          <img src="../assets/img/mobile/d2e-line-left.webp" class="w-full h-auto">
        </div>
        <div class="absolute top-[30%] right-[2%] w-[15%] h-auto z-20">
          <img src="../assets/img/mobile/d2e-line-right.webp" class="w-full h-auto">
        </div>
        <div @click="d2eConnectToggle" class="absolute top-[10%] left-0 w-full h-auto flex justify-center z-20">
          <video ref="d2eConnect" muted playsinline autoplay loop
            class="w-[83%] h-auto transition-all duration-500 opacity-0">
            <source src="../assets/video/mobile/d2e.mov" type="video/quicktime">
            <source src="../assets/video/mobile/d2e.webm" type="video/webm">
          </video>
          <div class="absolute top-0 left-0 w-full h-full">
            <div class="absolute top-0 left-0 w-full h-full flex justify-center items-center">
              <img src="../assets/img/gradient-background-earn.webp" class="h-full scale-[2.5] mix-blend-plus-lighter">
            </div>
            <div class="absolute top-0 left-0 w-full h-full flex justify-center items-center">
              <video ref="d2eCube" muted playsinline autoplay loop
                class="h-full w-auto transition-all duration-500 scale-125">
                <source src="../assets/video/mobile/cube.mov" type="video/quicktime">
                <source src="../assets/video/mobile/cube.webm" type="video/webm">
              </video>
            </div>
          </div>
        </div>
        <div class="absolute top-[33%] left-0 w-full h-[2%] flex justify-center">
          <div class="relative h-full w-2/5 rounded-full bg-gray-400 overflow-hidden">
            <div ref="d2eBgConnect"
              class="absolute top-0 left-0 w-3/4 h-full bg-[#516D02] rounded-full border border-[#A9E400] connect-box-shadow transition-all duration-300">
            </div>
            <div class="absolute top-0 left-0 w-full h-full flex">
              <div class="h-full w-3/4 flex justify-center items-center">
                <h1 ref="d2eTextConnect"
                  class="font-azonix text-[#000000] text-[3.5vw] leading-none font-normal text-center pt-[2%] transition-all duration-300">
                  CONNECT</h1>
              </div>
              <div class="h-full w-1/4 flex justify-start items-center">
                <img ref="d2eIconConnect" src="../assets/img/mobile/d2e-connect-icon.webp"
                  class="w-[70%] grayscale-[100%] ml-[10%]">
              </div>
            </div>
          </div>
        </div>
        <img src="../assets/img/mobile/d2e-card.webp" class="absolute top-[38%] left-[20%] w-[70%] h-auto z-30">
      </div>
      <div class="relative w-full h-[200vw] overflow-hidden">
        <div class="absolute top-0 left-0 w-full h-auto flex justify-center -translate-x-[3%]">
          <img src="../assets/img/mobile/advisor-title.webp" class="w-full">
          <div class="absolute top-[90%] left-0 w-full h-[2%] flex justify-center">
            <div class="w-3/5 h-full bg-gradient-to-r from-transparent via-[#c1ff18c7] to-transparent rounded-[50%]">
            </div>
          </div>
        </div>
        <div class="absolute top-[1%] right-[1.5%] w-[18%] h-auto">
          <img src="../assets/img/mobile/advisor-line-right.webp" class="w-full">
        </div>
        <div class="absolute bottom-0 left-[2%] w-[25%] h-auto">
          <img src="../assets/img/mobile/advisor-line-left.webp" class="w-full">
        </div>
        <div class="absolute top-[25%] left-0 w-[15%] h-auto">
          <img src="../assets/img/mobile/advisor-orgal-right.webp" class="w-full">
        </div>
        <div class="absolute top-[50%] right-[5%] w-[15%] h-auto">
          <img src="../assets/img/mobile/advisor-orgal-left.webp" class="w-full">
        </div>
        <div ref="advisorCard2" class="absolute top-[24%] left-[25%] w-[60%] h-auto transition-all duration-500">
          <img src="../assets/img/mobile/advisor-card2.webp" class="w-full">
        </div>
        <div ref="advisorCard1" class="absolute top-[22%] left-[5%] w-[76%] h-auto transition-all duration-500">
          <img src="../assets/img/mobile/advisor-card1.webp" class="w-full">
        </div>
        <div class="absolute bottom-0 left-0 w-full h-[13%] flex justify-center items-center gap-[10%]">
          <div @click="aiVisorToggle(1)" ref="advisorBtnLeft" btn
            class="h-1/2 aspect-square border border-[#c1ff1875] rounded-lg flex justify-center items-center transition-all about-box-shadow-btn">
            <img src="../assets/img/mobile/about-arrow-left-line.png" class="w-[60%]">
          </div>
          <div @click="aiVisorToggle(2)" ref="advisorBtnRight" btn
            class="h-1/2 aspect-square border border-[#c1ff1875] rounded-lg flex justify-center items-center transition-all">
            <img src="../assets/img/mobile/about-arrow-right-line.png" class="w-[60%]">
          </div>
        </div>
      </div>
      <div class="relative w-full h-auto mt-[10vw]">
        <video muted playsinline autoplay loop class="w-full h-auto">
          <source src="../assets/video/mobile/node.webm" type="video/webm">
        </video>
        <div class="absolute top-[6%] left-0 w-full h-auto flex justify-center">
          <img src="../assets/img/mobile/node-title.webp" class="w-[90%]">
        </div>
        <div class="absolute bottom-0 left-0 w-full h-auto flex justify-center">
          <img src="../assets/img/mobile/node-description.webp" class="w-[95%] ml-[0.8%]">
        </div>
      </div>
      <div class="relative w-full h-[35vw] flex justify-center">
        <img src="../assets/img/background-partner.webp" class="h-full w-auto">
        <div class="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-[#c1ff1825] to-transparent"></div>
        <div class="absolute top-0 left-0 w-full h-full flex justify-center items-end">
          <img src="../assets/img/title-partner.webp" class="h-auto w-[90%]">
        </div>
      </div>
      <div
        class="relative h-[40vw] w-full bg-gradient-to-b from-[#c1ff1825] to-transparent flex justify-center items-center">
        <div class="h-full w-[95%] overflow-y-scroll no-scrollbar flex items-center gap-[10vw]">
          <div class="min-w-[2vw]"></div>
          <div btn v-for="partner in partnerList" :key="partner" @click="openWebLink(partner.link)"
            class="relative h-auto min-w-[50vw] transition-all">
            <img src="../assets/img/tag-partner.webp" class="w-full h-auto">
            <div class="absolute top-0 left-0 w-full h-full flex justify-start items-center">
              <img :src="partner.logoUrl" class="h-1/2 w-auto ml-[2vw] mr-[1vw]">
              <h1 class="font-azonix text-[#fff] text-[5vw] leading-none pt-[2%] font-normal">{{ partner.name }}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- footer mobile -->
      <img src="../assets/img/mobile/footer-line.webp" class="h-auto w-full">
      <div class="relative w-full h-[30vw] mt-[10vw] mb-[7vw] flex justify-center">
        <div class="h-full w-[95%] flex">
          <div class="h-full w-1/2 flex items-start justify-start flex-col">
            <div class="w-full h-[30%] flex gap-[2%] items-center">
              <img src="../assets/img/logo.webp" class="h-full">
              <h1 class="font-azonix text-[#fff] text-[6vw] leading-none font-bold pt-[1%]">
                DEFIN</h1>
            </div>
            <div class="relative h-auto w-fit mt-[5%]">
              <h1 class="font-azonix text-[#fff] text-[2.9vw] leading-none font-normal pt-[1%]">
                Contribute to Earn now</h1>
              <div
                class="absolute top-[-20%] left-0 w-full h-[10%] bg-gradient-to-r from-[#c1ff18] to-transparent -translate-y-[130%]">
              </div>
            </div>
            <div class="h-auto w-full mt-[10%]">
              <div class="w-full h-auto flex justify-start gap-[5%]">
                <img btn @click="openWebLink('https://t.me/defin_ann')" src="../assets/img/social/tele.webp"
                  class="w-[15%] h-auto">
                <img btn @click="openWebLink('https://t.me/defin_official')" src="../assets/img/social/tele.webp"
                  class="w-[15%] h-auto">
                <img btn @click="openWebLink('https://x.com/DeFIN_app')" src="../assets/img/social/x.webp"
                  class="w-[15%] h-auto">
                <img btn @click="openWebLink('#')" src="../assets/img/social/doc.webp" class="w-[15%] h-auto">
              </div>
            </div>
          </div>
          <div class="h-full w-1/2 flex items-end justify-start flex-col">
            <div class="w-full h-auto flex justify-end mt-[3%] mb-[20%]">
              <img btn @click="openWebLink('https://t.me/xdefin_bot')" src="../assets/img/lauchapp-btn.png"
                class="w-[80%] h-auto">
            </div>
            <h1 class="font-azonix text-[#fff] text-[2.9vw] leading-none font-normal mb-[3%]">
              Terms of Service</h1>
            <h1 class="font-azonix text-[#fff] text-[2.9vw] leading-none font-normal">
              Privacy Policy</h1>
          </div>
        </div>
      </div>
      <div class="relative h-auto w-full">
        <img src="../assets/img/mobile/footer-background.webp" class="h-auto w-full">
        <div class="absolute bottom-0 left-0 w-full h-[60%] flex justify-center items-center gap-[2%]">
          <img src="../assets/img/copyright.webp" class="h-[25%]">
          <h1 class="font-azonix text-[#fff] text-[3vw] leading-none font-normal pt-[1%]">2024 DEFIN. All rights
            reserved.</h1>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import LocomotiveScroll from 'locomotive-scroll';
import { useState } from '../controller/state';

export default {
  name: 'HomeDefin',

  setup() {
    const windowWidth = ref(window.innerWidth);
    const isMobile = computed(() => windowWidth.value <= 768);
    const initialIsMobile = ref(isMobile.value);
    const state = useState();
    const homeFrame = ref(null);
    const about = ref(null);
    const earn = ref(null);
    const aiModel = ref(null);
    const titleEarn = ref(null);
    const detailEarn = ref(null);
    const backgroundEarn = ref(null);
    const starsEarn = ref(null);
    const detailBtn = ref(null);
    const detailBackBtn = ref(null);
    const titleFrameBottom = ref(null);
    const detailFrameBottom = ref(null);
    const privateEarn = ref(null);
    const privateConnect = ref(null);
    const data2Earn = ref(null);
    const locoScroll = ref(null);
    const aboutNavBtn = ref(null);
    const earnNavBtn = ref(null);
    const verifierNode = ref(null);
    const nodeSaleNavBtn = ref(null);
    const aboutDetail1 = ref(null);
    const aboutDetail2 = ref(null);
    const aboutDetailBtnLeft = ref(null);
    const aboutDetailBtnRight = ref(null);
    const aboutDetailIndex = ref(1);
    const about2Chip = ref(null);
    const about2AIModel = ref(null);
    const about2Title = ref(null);
    const about2Line = ref(null);
    const about2Detail = ref(null);
    const about2MoreDetail2Btn = ref(null);
    const about2BackBtn = ref(null);
    const about2CardTitle = ref(null);
    const about2CardContent = ref(null);
    const d2eConnect = ref(null);
    const d2eCube = ref(null);
    const isD2eConnect = ref(false);
    const d2eBgConnect = ref(null);
    const d2eTextConnect = ref(null);
    const d2eIconConnect = ref(null);
    const advisorCard2 = ref(null);
    const advisorCard1 = ref(null);
    const advisorBtnLeft = ref(null);
    const advisorBtnRight = ref(null);


    const partnerList = ref([
      {
        logoUrl: require('../assets/img/partner/chaingpt.webp'),
        link: 'https://chaingpt.org/',
        name: 'CHAINGPT'
      },
      {
        logoUrl: require('../assets/img/partner/chaingpt.webp'),
        link: 'https://chaingpt.org/',
        name: 'CHAINGPT'
      },
      {
        logoUrl: require('../assets/img/partner/chaingpt.webp'),
        link: 'https://chaingpt.org/',
        name: 'CHAINGPT'
      },
      {
        logoUrl: require('../assets/img/partner/chaingpt.webp'),
        link: 'https://chaingpt.org/',
        name: 'CHAINGPT'
      },
      {
        logoUrl: require('../assets/img/partner/chaingpt.webp'),
        link: 'https://chaingpt.org/',
        name: 'CHAINGPT'
      },
      {
        logoUrl: require('../assets/img/partner/chaingpt.webp'),
        link: 'https://chaingpt.org/',
        name: 'CHAINGPT'
      }
    ]);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
      if (isMobile.value !== initialIsMobile.value) {
        window.location.reload(true);
      }
    };

    function waitLoadingFinish(callback) {
      const checkInterval = setInterval(() => {
        if (state.getLoadingState()) {
          clearInterval(checkInterval);
          callback();
        }
      }, 1);
    }

    function scrollToElement(element) {
      locoScroll.value.scrollTo(element, {
        offset:
          - (window.innerHeight / 2)
          + (element.getBoundingClientRect().height / 2)
          - (0.03 * window.innerWidth),
        duration: 700,
        easing: [0.0, 0.3, 0.5, 1]
      });
    }

    function earnMoreDetailRightBtnClick() {
      aiModel.value.classList.add('-translate-x-[9vw]');
      aiModel.value.classList.add('scale-90');
      titleEarn.value.classList.add('-translate-x-[5vw]');
      detailEarn.value.classList.remove('opacity-0');
      backgroundEarn.value.classList.add('translate-x-[6vw]');
      starsEarn.value.classList.add('translate-x-[5vw]');
      starsEarn.value.classList.add('-translate-y-[3vw]');
      detailBtn.value.classList.add('hidden');
      detailBackBtn.value.classList.remove('hidden');
      privateEarn.value.classList.add('-translate-x-[3vw]');
      privateEarn.value.classList.add('scale-[0.85]');
      privateConnect.value.classList.add('-translate-x-[5vw]');
      privateConnect.value.classList.add('scale-[0.85]');
    }

    function closeMoreDetailRightBtnClick() {
      aiModel.value.classList.remove('-translate-x-[9vw]');
      aiModel.value.classList.remove('scale-90');
      titleEarn.value.classList.remove('-translate-x-[5vw]');
      detailEarn.value.classList.add('opacity-0');
      backgroundEarn.value.classList.remove('translate-x-[6vw]');
      starsEarn.value.classList.remove('translate-x-[5vw]');
      starsEarn.value.classList.remove('-translate-y-[3vw]');
      detailBtn.value.classList.remove('hidden');
      detailBackBtn.value.classList.add('hidden');
      privateEarn.value.classList.remove('-translate-x-[3vw]');
      privateEarn.value.classList.remove('scale-[0.85]');
      privateConnect.value.classList.remove('-translate-x-[5vw]');
      privateConnect.value.classList.remove('scale-[0.85]');
    }

    function earnMoreDetailBottomBtnClick() {
      titleFrameBottom.value.classList.add('opacity-0');
      detailFrameBottom.value.classList.remove('opacity-0');
    }

    function closeMoreDetailBottomBtnClick() {
      titleFrameBottom.value.classList.remove('opacity-0');
      detailFrameBottom.value.classList.add('opacity-0');
    }

    function checkElementInViewport(elements) {
      const viewportHeight = window.innerHeight;
      const viewportMiddle = viewportHeight / 2;

      elements.forEach(element => {
        const rect = element.getBoundingClientRect();
        const elementMiddle = rect.top + rect.height / 2;
        if (Math.abs(elementMiddle - viewportMiddle) < (viewportHeight / 2)) {
          if (element.id == 'about' || element.id == 'earn') {
            if (!aboutNavBtn.value.classList.contains('nav-active')) {
              aboutNavBtn.value.classList.add('nav-active');
            }
          } else {
            if (aboutNavBtn.value.classList.contains('nav-active')) {
              aboutNavBtn.value.classList.remove('nav-active');
            }
          }
          if (element.id == 'data2Earn' || element.id == 'advisor') {
            if (!earnNavBtn.value.classList.contains('nav-active')) {
              earnNavBtn.value.classList.add('nav-active');
            }
          } else {
            if (earnNavBtn.value.classList.contains('nav-active')) {
              earnNavBtn.value.classList.remove('nav-active');
            }
          }
          if (element.id == 'verifierNode') {
            if (!nodeSaleNavBtn.value.classList.contains('nav-active')) {
              nodeSaleNavBtn.value.classList.add('nav-active');
            }
          } else {
            if (nodeSaleNavBtn.value.classList.contains('nav-active')) {
              nodeSaleNavBtn.value.classList.remove('nav-active');
            }
          }
        }
      });
    }

    function openWebLink(url) {
      setTimeout(() => {
        window.open(url, '_blank');
      }, 300);
    }

    function handleMobileBtnClickAnimation() {
      const buttons = document.querySelectorAll('[btn]');
      buttons.forEach(btn => {
        btn.addEventListener('touchstart', () => {
          btn.style.transform = 'scale(0.9)';
        });

        btn.addEventListener('touchend', () => {
          btn.style.transform = 'scale(1)';
        });
      });
    }

    function detailAboutToggle(tab) {
      if (tab == aboutDetailIndex.value) {
        return;
      }
      if (tab === 1) {
        aboutDetail2.value.classList.add('opacity-0');
        aboutDetailBtnRight.value.classList.remove('about-box-shadow-btn')
        setTimeout(() => {
          aboutDetail2.value.classList.add('hidden');
          aboutDetail1.value.classList.remove('hidden');
          setTimeout(() => {
            aboutDetail1.value.classList.remove('opacity-0');
          }, 10);
        }, 300);
        setTimeout(() => {
          aboutDetailBtnLeft.value.classList.add('about-box-shadow-btn')
        }, 150);
      }
      if (tab === 2) {
        aboutDetail1.value.classList.add('opacity-0');
        aboutDetailBtnLeft.value.classList.remove('about-box-shadow-btn')
        setTimeout(() => {
          aboutDetail1.value.classList.add('hidden');
          aboutDetail2.value.classList.remove('hidden');
          setTimeout(() => {
            aboutDetail2.value.classList.remove('opacity-0');
          }, 10);
        }, 300);
        setTimeout(() => {
          aboutDetailBtnRight.value.classList.add('about-box-shadow-btn')
        }, 150);
      }
      aboutDetailIndex.value = tab;
    }

    function detailAbout2Toggle(tab) {
      if (tab === 1) {
        about2Title.value.classList.add('opacity-0');
        setTimeout(() => {
          about2Title.value.classList.add('hidden');
          about2Detail.value.classList.remove('hidden');
          setTimeout(() => {
            about2Detail.value.classList.remove('opacity-0');
          }, 10);
        }, 300);
        about2AIModel.value.classList.add('-translate-x-[35%]');
        about2Chip.value.classList.add('translate-x-[70%]');
        about2Chip.value.classList.add('scale-150');
        about2Line.value.classList.add('translate-y-[30vw]');
      }
      if (tab === 2) {
        about2Detail.value.classList.add('opacity-0');
        setTimeout(() => {
          about2Detail.value.classList.add('hidden');
          about2Title.value.classList.remove('hidden');
          setTimeout(() => {
            about2Title.value.classList.remove('opacity-0');
          }, 10);
        }, 300);
        about2AIModel.value.classList.remove('-translate-x-[35%]');
        about2Chip.value.classList.remove('translate-x-[70%]');
        about2Chip.value.classList.remove('scale-150');
        about2Line.value.classList.remove('translate-y-[30vw]');
      }
    }

    function detail2About2Toggle(tab) {
      if (tab === 1) {
        about2CardTitle.value.classList.add('opacity-0');
        setTimeout(() => {
          about2CardTitle.value.classList.add('hidden');
          about2CardContent.value.classList.remove('hidden');
          setTimeout(() => {
            about2CardContent.value.classList.remove('opacity-0');
          }, 10);
        }, 300);
        about2MoreDetail2Btn.value.classList.add('opacity-0');
        setTimeout(() => {
          about2MoreDetail2Btn.value.classList.add('hidden');
          about2BackBtn.value.classList.remove('hidden');
          setTimeout(() => {
            about2BackBtn.value.classList.remove('opacity-0');
          }, 10);
        }, 150);
      }
      if (tab === 2) {
        about2CardContent.value.classList.add('opacity-0');
        setTimeout(() => {
          about2CardContent.value.classList.add('hidden');
          about2CardTitle.value.classList.remove('hidden');
          setTimeout(() => {
            about2CardTitle.value.classList.remove('opacity-0');
          }, 10);
        }, 300);
        about2BackBtn.value.classList.add('opacity-0');
        setTimeout(() => {
          about2BackBtn.value.classList.add('hidden');
          about2MoreDetail2Btn.value.classList.remove('hidden');
          setTimeout(() => {
            about2MoreDetail2Btn.value.classList.remove('opacity-0');
          }, 10);
        }, 150);
      }
    }

    function d2eConnectToggle() {
      if (!isD2eConnect.value) {
        d2eConnect.value.classList.remove('opacity-0');
        d2eCube.value.classList.add('opacity-50');
        d2eCube.value.classList.add('scale-[0.3]');
        d2eCube.value.classList.add('-translate-y-[7%]');
        d2eBgConnect.value.classList.add('w-full');
        d2eBgConnect.value.classList.add('bg-gradient-to-r');
        d2eBgConnect.value.classList.add('from-[#A9E400]');
        d2eBgConnect.value.classList.add('to-[#fff]');
        d2eIconConnect.value.classList.remove('grayscale-[100%]');
        d2eIconConnect.value.classList.add('connect-icon-custom');
        d2eTextConnect.value.innerHTML = 'CONNECTED'
      } else {
        d2eConnect.value.classList.add('opacity-0');
        d2eCube.value.classList.remove('opacity-50');
        d2eCube.value.classList.remove('scale-[0.3]');
        d2eCube.value.classList.remove('-translate-y-[7%]');
        d2eBgConnect.value.classList.remove('w-full');
        d2eBgConnect.value.classList.remove('bg-gradient-to-r');
        d2eBgConnect.value.classList.remove('from-[#A9E400]');
        d2eBgConnect.value.classList.remove('to-[#fff]');
        d2eIconConnect.value.classList.add('grayscale-[100%]');
        d2eIconConnect.value.classList.remove('connect-icon-custom');
        d2eTextConnect.value.innerHTML = 'CONNECT'
      }
      isD2eConnect.value = !isD2eConnect.value;
    }

    function aiVisorToggle(tab) {
      if (tab === 1) {
        advisorCard2.value.classList.add('opacity-70');
        advisorCard2.value.classList.remove('z-10');
        setTimeout(() => {
          advisorCard2.value.classList.remove('opacity-70');
        }, 500);
        advisorCard2.value.classList.remove('-translate-y-[3.5%]');
        advisorCard2.value.classList.remove('-translate-x-[7%]');
        advisorCard1.value.classList.remove('translate-y-[3.5%]');
        advisorCard1.value.classList.remove('translate-x-[5.3%]');
        advisorBtnLeft.value.classList.add('about-box-shadow-btn');
        advisorBtnRight.value.classList.remove('about-box-shadow-btn');
      }
      if (tab === 2) {
        advisorCard1.value.classList.add('opacity-70');
        advisorCard2.value.classList.add('z-10');
        setTimeout(() => {
          advisorCard1.value.classList.remove('opacity-70');
        }, 500);
        advisorCard2.value.classList.add('-translate-y-[3.5%]');
        advisorCard2.value.classList.add('-translate-x-[7%]');
        advisorCard1.value.classList.add('translate-y-[3.5%]');
        advisorCard1.value.classList.add('translate-x-[5.3%]');
        advisorBtnLeft.value.classList.remove('about-box-shadow-btn');
        advisorBtnRight.value.classList.add('about-box-shadow-btn');
      }
    }

    onMounted(() => {
      window.addEventListener('resize', handleResize);
      handleMobileBtnClickAnimation();
      waitLoadingFinish(() => {
        if (!isMobile.value) {
          locoScroll.value = new LocomotiveScroll({
            el: document.querySelector('[data-scroll-container]'),
            smooth: true,
            multiplier: 0.7,
          });
          const elements = document.querySelectorAll('[data-scroll-section]');
          checkElementInViewport(elements);
          locoScroll.value.on('scroll', () => {
            checkElementInViewport(elements);
          });
        }
      });
    });

    return {
      isMobile,
      homeFrame,
      about,
      scrollToElement,
      earn,
      aiModel,
      titleEarn,
      earnMoreDetailRightBtnClick,
      detailEarn,
      backgroundEarn,
      starsEarn,
      closeMoreDetailRightBtnClick,
      detailBtn,
      detailBackBtn,
      titleFrameBottom,
      detailFrameBottom,
      earnMoreDetailBottomBtnClick,
      closeMoreDetailBottomBtnClick,
      privateEarn,
      privateConnect,
      data2Earn,
      locoScroll,
      aboutNavBtn,
      earnNavBtn,
      verifierNode,
      nodeSaleNavBtn,
      partnerList,
      openWebLink,
      aboutDetail1,
      aboutDetail2,
      aboutDetailBtnLeft,
      aboutDetailBtnRight,
      aboutDetailIndex,
      detailAboutToggle,
      about2Chip,
      about2AIModel,
      about2Title,
      about2Line,
      about2Detail,
      detailAbout2Toggle,
      about2MoreDetail2Btn,
      about2BackBtn,
      about2CardTitle,
      about2CardContent,
      detail2About2Toggle,
      d2eConnect,
      d2eCube,
      d2eConnectToggle,
      d2eBgConnect,
      d2eTextConnect,
      d2eIconConnect,
      aiVisorToggle,
      advisorCard2,
      advisorCard1,
      advisorBtnLeft,
      advisorBtnRight
    }
  }
}
</script>

<style scoped>
.box-shadow {
  box-shadow: 0 0 27.5px 0 #e8ff4b40;
}

.nav-btn-hover {
  cursor: pointer;
}

.nav-btn-hover:hover h1 {
  background: linear-gradient(to right, #bbff00 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.nav-btn-hover:hover .nav-btn {
  opacity: 100;
}

.nav-active h1 {
  background: linear-gradient(to right, #bbff00 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.nav-active .nav-btn {
  opacity: 100;
}

.cube-animation {
  animation: cube-animation 10s ease-in-out infinite;
}

@keyframes cube-animation {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(3vw);
  }

  50% {
    transform: translateY(0);
  }

  75% {
    transform: translateY(3vw);
  }

  100% {
    transform: translateY(0);
  }
}

.boxshadow-btn-detail,
.boxshadow-btn-back-detail {
  box-shadow: 0px 0px 6px 3px rgba(255, 255, 255, 0.25) inset;
}

.btn-detail:hover .btn-bg-left {
  background-color: #bbff00;
}

.btn-detail:hover .btn-text-left {
  color: #000000ce;
}

.btn-detail:hover .btn-bg-right {
  background-color: gray;
  box-shadow: 0px 0px 6px 3px rgba(255, 255, 255, 0.25) inset;
}

.btn-detail:hover .btn-text-right {
  transform: rotate(-45deg);
}

.btn-back-detail:hover .bg-back-detail-left {
  background-color: gray;
  box-shadow: 0px 0px 6px 3px rgba(255, 255, 255, 0.25) inset;
}

.btn-back-detail:hover .btn-text-back-detail-left {
  transform: rotate(0);
}

.btn-back-detail:hover .bg-back-detail-right {
  background-color: #bbff00;
}

.btn-back-detail:hover .btn-text-back-detail-right {
  color: #000000ce;
}

.partner-hover-custom {
  filter: drop-shadow(0 0 0px #000000);
  -webkit-filter: drop-shadow(0 0 0 #000000);
}

.partner-hover-custom:hover {
  filter: drop-shadow(0 0 0px #bbff00);
  -webkit-filter: drop-shadow(0 0 0 #bbff00);
}

.about-box-shadow-btn {
  box-shadow: 0 0 5px 0 #bbff00;
}

.connect-box-shadow {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.81) inset, 2px 0px 4px 0px rgba(0, 0, 0, 0.51);
}

.connect-icon-custom {
  filter: drop-shadow(0 0 1px #000000);
  -webkit-filter: drop-shadow(0 0 1px #000000);
}
</style>